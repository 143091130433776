<template>
	<div>
		数据统计-敬请期待

	</div>
</template>

<script>

export default {
	components: { },
	data() {
		return {
			NotifyList: [],
			article_id: 0,
			defaultProps: {
				label: 'name',
				value: 'id',
				children: 'children',
				emitPath: false,
				checkStrictly: true
			},
			deptId: 0,
			yujingStatus:0,
			ckdTreeData: [],
			testTree:""

		};
	},
	mounted() {
		
	},
	watch:{
		testTree:function(n,o){
			console.log(n)
		}
	},
	methods: {
		getTestTreeData(e){
			console.log(e)
		},
		getDeptID(e){
			console.log("getDeptID")
			console.log(e)
			console.log(this.deptId)
		},
		getTree() {

			this.$http.post("/api/get_tch_cls_data", {}).then(res => {
				res.data.list.map(e => {
					e.name = e.upname + "/" + e.name
				})
				this.treeData = res.data.list
				this.ckdTreeData = this.treeData
			})
		},
		getNotify() {
			this.$http.post("/api/notify_list_list").then(res => {
				for (let item of res.data.data) {
					item.cdate = item.cdate.split(' ')[0]
				}
				this.NotifyList = res.data.data
			})
		},
		closePhone() {
			this.article_id = 0
		},
		goPage(path) {
			this.$router.push(path)
		},
		getYujing(){
			this.$refs['yujing'].getData(this.yujingStatus,this.deptId)
		},
		exportYujing(){
			this.$refs['yujing'].exportXls(this.yujingStatus,this.deptId)
		}
		
	}
}


</script>

<style scoped="scoped">
.headerbox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	padding: 20px 1px;
}

.headitem {
	border: 0px solid #eee;
	box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 60px 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	cursor: pointer;
	text-decoration: none;
	box-sizing: border-box;
}

.headitem img {
	width: 40px;
	height: 40px;
}

.headitem span {
	padding-left: 20px;
	font-size: 18px;
}

.rowitem {
	display: flex;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	border-bottom: 1px solid #eee;
}

.rowtitle {
	width: 80%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.rowdate {
	width: 20%;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
</style>
